.Background {
  background: linear-gradient(-45deg, #242424, #fdc100, #ffffff);
  background-size: 380% 380%;
  /* animation: gradient 15s ease infinite; */
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
